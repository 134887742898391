import "./styles.css";
import "./Components/Dropdown";
import Dropdown from "./Components/Dropdown";
export default function App() {
  return (
    <div className="App">
      <Dropdown />
    </div>
  );
}
